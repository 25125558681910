import React, { FC, useEffect } from 'react';
import { Container, DangerouslySetInnerHtml, Layout } from 'layout';
import { useUpdateOTLds } from '@phx-husky/use-update-ot-lds';

import { graphql } from 'gatsby';
import SimpleBanner from 'components/SimpleBanner';
import { AllLds } from '@shared/types/umbraco';
import SignUp from 'components/SignUp';
import BodyRenderer from 'common/BodyRenderer';
import './LdsPage.scss';
import './LdsPageOverride.scss';

const elements = {
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
  'Body Info': ({ properties }, keyId) => (
    <Container fluid>
      <DangerouslySetInnerHtml key={keyId} className="lds-description" html={properties.body} />
    </Container>
  ),
};

const LdsPage: FC<AllLds.Page> = ({
  pageContext: { breadCrumbs, link, searchUrl },
  data: {
    allLds: {
      nodes: [
        {
          topBanner: banner,
          defaultCompositions,
          bodyInfo,
          seoMetaKeywords,
          seoMetaTitle,
          seoMetaTitleTemplate,
          seoMetaDescription,
          seoExternalHreflangs,
          seoCanonicalUrl,
          seoNoIndex,
          alternateUrls,
          ogImageUrl,
        },
      ],
    },
  },
}) => {
  const { businessId, versionId, platform } = bodyInfo[0].properties;
  const { updatedLdsContent, isCheckingLdsVersion } = useUpdateOTLds({
    baseUrl: process.env.GATSBY_LDS_BASE_URL,
    businessId,
    versionId,
    platform,
  });

  useEffect(() => {
    if (updatedLdsContent) {
      elements['Body Info'] = (_, keyId) => (
        <Container fluid>
          <DangerouslySetInnerHtml
            key={keyId}
            className="lds-description"
            html={updatedLdsContent}
          />
        </Container>
      );
    }
  }, [updatedLdsContent]);

  const { singupFormBaner, waiSettings } = defaultCompositions;
  const imageUrl = ogImageUrl || banner?.[0]?.properties?.image?.fallbackUrl;
  const imageAlt = banner?.[0]?.properties?.title;

  return (
    <Layout
      {...{
        ...defaultCompositions,
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaTitleTemplate,
        seoNoIndex,
        seoMetaDescription,
        seoExternalHreflangs,
        seoCanonicalUrl,
        skipText: waiSettings?.skipText,
        link,
        searchUrl,
        alternateUrls,
        imageUrl,
        imageAlt,
      }}
    >
      {!isCheckingLdsVersion && (
        <section className="lds-page">
          {banner?.length ? <SimpleBanner {...{ ...banner[0].properties, breadCrumbs }} /> : null}
          <BodyRenderer
            bodyData={bodyInfo}
            bodyStructure={elements}
            bodyItemProps={{ ...{ singupFormBaner } }}
          />
        </section>
      )}
    </Layout>
  );
};
export default LdsPage;

export const query = graphql`
  query LdsPageQuery($link: String) {
    allLds(filter: { link: { eq: $link } }) {
      nodes {
        seoMetaTitle
        seoMetaTitleTemplate
        seoMetaDescription
        seoMetaKeywords
        seoNoIndex
        seoExternalHreflangs {
          key
          value
        }
        seoCanonicalUrl {
          url
        }
        alternateUrls {
          lang
          url
          path
        }
        ogImageUrl
        defaultCompositions {
          footer {
            ...FragmentFooter
          }
          waiSettings {
            skipText
          }
          header {
            ...FragmentHeader
          }
          siteSettings {
            ...FragmentSiteSettingsComposition
          }
          brandSettings {
            ...FragmentBrandSettingsComposition
          }
          singupFormBaner {
            ...FragmentSignUpBanner
          }
          warning {
            ...FragmentWarning
          }
          signUpPopup {
            ...FragmentSignUpPopup
          }
          signUpFormPopup {
            ...FragmentSignUpFormPopup
          }
          promoPlatformForm {
            ...FragmentPromoPlatformForm
          }
        }
        title
        topBanner {
          properties {
            description
            title
            titleSize
            titleSizeMobile
          }
        }
        bodyInfo {
          ... on TBodyInfo {
            structure
            properties {
              body
              businessId
              versionId
              platform
            }
          }
          ... on TSignupPlaceholder {
            ...FragmentSignUp
          }
        }
      }
    }
  }
`;
